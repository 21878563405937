const routes = require('next-routes')

module.exports = routes()
  .add('/', '/')
  .add('/login', '/public/authentication/login')
  .add('/signup', '/public/authentication/signup')
  .add('/dashboard', '/dashboard')
  .add('/urunler', '/dashboard/products')
  .add('/indirimler', '/dashboard/gifts')
  .add('/kategoriler', '/dashboard/categories')
  .add('/musteriler', '/dashboard/customers')
  .add('/siparisler', '/dashboard/orders')
  .add('/fastorders', '/dashboard/FastOrders')
  .add('/yenisiparis', '/public/order')
  .add('fail', '/yenisiparis/:query/:code', '/public/order')
  .add('/siparislerim', '/public/myorders')
  .add('/gizlilik', '/public/others/gizlilik')
  .add('/sozlesme', '/public/others/sozlesme')
  .add('/success', '/public/order/succcess')
  .add('urun', '/urun/:id', 'public/product')
  .add('siparis', '/siparis/:key/:id', 'public/order/orderResult')
  .add('/sitemap.xml', '/sitemap.xml')
  .add('/feed.xml', '/feed.xml')
  .add('/customer-login', '/public/customer/LoginPage')
  .add('/profile', '/public/customer/ProfilePage')
  .add('/orders-page', '/public/customer/OrdersPage')
  .add('/address-page', 'public/customer/AddressPage')
  .add('/payment', '/public/payment/PaymentPage')
  .add('/yorumlar', '/dashboard/Comments')
  .add('/landing/karisim', '/public/landing/Karisim')
  .add('/teklifler', '/dashboard/offers')
  .add('/yukleme', '/dashboard/yukleme')
  .add('/kargotakip', '/dashboard/kargotakip')
